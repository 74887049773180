import { Component, OnInit, Inject } from '@angular/core';
import { SurveyPurchaseModel } from 'src/app/shared/models/surveyMarketingStrategy.models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PurchaseCallbackModel } from 'src/app/shared/models/order.models';

@Component({
  selector: 'app-purchase-modal',
  templateUrl: './purchase-modal.component.html',
})
export class PurchaseModalComponent implements OnInit {
  purchaseModel!: SurveyPurchaseModel;

  constructor(@Inject(MAT_DIALOG_DATA) private parameters: PurchaseModalParameters,
    private dialogRef: MatDialogRef<PurchaseModalComponent>) { }

  ngOnInit(): void {
    this.purchaseModel = this.parameters.purchaseModel;
  }

  payComponentCallback(callback: PurchaseCallbackModel): void {
    this.dialogRef.close({ paymentAmount: callback.paymentAmount });
  }

  closeModal(): void {
    this.dialogRef.close();
  }


}

export interface PurchaseModalParameters {
  purchaseModel: SurveyPurchaseModel;
}
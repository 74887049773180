<section class="flex flex-col p-20 m-10 rounded bg-gray-lightest gap-y-20">
  @if (purchaseModel.Title) {
    <h1 class="font-bold">{{purchaseModel.Title}} Purchase Options</h1>
  }
  @if (purchaseModel.PurchaseOptions) {
    <p>
      Select a purchase option below:
    </p>
  }
  <form [formGroup]="paymentForm" (ngSubmit)="purchase()" class="flex flex-col gap-y-20">
    <mat-radio-group formControlName="selectedPurchaseOption" class="flex flex-col" [(ngModel)]="selectedPurchaseOption"
      (ngModelChange)="getPaymentAmount()">
      <mat-radio-button *ngFor="let po of purchaseModel.PurchaseOptions index as i" [value]="i"
        [checked]="i === selectedPurchaseOption">
        <span class="font-bold">{{po.Description}} - ${{po.Amount}}</span>
      </mat-radio-button>
    </mat-radio-group>
    @if (purchaseModel.AgentMarketingDollars && purchaseModel.AgentMarketingDollars > 0) {
      <div class="flex flex-col gap-y-20">
        <p>
          You currently have&nbsp;&nbsp;<span class="font-bold text-blue-dark">{{purchaseModel.AgentMarketingDollars |
        currency}}</span>&nbsp;&nbsp;marketing dollars available. Would you like to use any for this purchase?
      </p>
      <div class="flex items-center justify-between gap-x-20">
        <div class="flex items-center">
          <label for="marketingDollarsToUse" class="mr-10 text-sm text-gray">Marketing Dollars To Use: </label>
          <input formControlName="marketingDollarsToUse" [(ngModel)]="marketingDollarsToUse" currencyMask class="input"
            (ngModelChange)="getPaymentAmount()" />
          </div>
          <div class="flex items-center">
            <span class="mr-10 text-sm text-gray">You Pay:</span>
            <p class="font-bold text-blue-dark">{{paymentAmount | currency}}</p>
          </div>
        </div>
      </div>
    }
    <div class="flex flex-row items-center justify-between mt-10">
      @if (purchaseModel.TermsLink) {
        <a mat-button class="flex items-center justify-center button is-primary is-outlined" target="_blank"
          href="{{purchaseModel.TermsLink}}">
          <span class="leading-normal">View Terms</span>
        </a>
      }
      <button type="submit" class="ml-auto button is-primary " mat-button
      [disabled]="selectedPurchaseOption === undefined">Purchase Renewal</button>
    </div>
  </form>
</section>
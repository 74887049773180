@if (toggleNewForm && !loading) {
  <form class="flex flex-col flex-shrink-0 w-full h-full overflow-hidden" #enrollmentForm="ngForm" (ngSubmit)="submitForm()">
    @if (!loading) {
      <section id="enrollmentFormToolbar"
        class="flex-none border-b border-solid p-30 border-gray-lighter">
        <h2 class="text-lg font-bold heading">
          @if (survey.SurveyId === 1025) {
            <span>
              It looks like you do not have an {{survey.SurveyName}} account. <br />
              Submit the form below to enroll today!
            </span>
          }@else {
            <span>
              Enroll in {{survey.SurveyName}} today!
            </span>
          }
        </h2>
        <button class="absolute top-0 right-0 m-20 icon-button is-primary is-inverted" aria-label="Close"
          (click)="closeModal(false)" type="button">
          <i class="material-icons">close</i>
        </button>
      </section>
    }
    <section div class="flex flex-col flex-grow min-h-0 overflow-y-auto p-30">
      @if (!loading) {
        <div>
          <div class="flex flex-row flex-wrap items-end">
            @for (q of questions; track q; let index = $index) {
              <div class="flex w-full sm:w-1/2">
                @if (q.SurveyQuestionTypeId === questionType.Text) {
                  <div class="flex flex-col flex-grow mx-10 mt-20">
                    <label [for]="q.AnswerValue" class="mb-10 text-sm font-semibold label text-gray">
                      {{q.SurveyQuestionText}} 
                      @if (q.IsRequired) {
                      <span class="text-red">(required)</span>
                    }
                  </label>
                  <input class="h-40 px-10 border border-solid input is-inverted border-gray-light" type="text"
                    name="question{{q.SurveyQuestionId}}" [required]="q.IsRequired" [(ngModel)]="q.AnswerValue"/>
                  </div>
                }
                @if (q.SurveyQuestionTypeId === questionType.Radio) {
                  <div class="flex flex-col flex-grow mx-10 mt-20">
                    <label [for]="q.AnswerValue" class="mb-5 text-sm label is-semibold is-white">{{q.SurveyQuestionText}} @if (q.IsRequired) {
                      <span
                      class="text-red">(required)</span>
                    }</label>
                    <mat-radio-group layout="column" [(ngModel)]="q.AnswerValue"
                      (change)="questionAnsweredNoText(q.SurveyQuestionId, $event.value)">
                      @for (a of q.PossibleAnswers; track a) {
                        <div class="flex flex-row items-center h-40">
                          @if (!a.IsText) {
                            <mat-radio-button class="w-full p-10 radio-button is-primary-2 md:w-1/3"
                              [value]="a.PossibleAnswerId">{{a.PossibleAnswerText}}
                            </mat-radio-button>
                          }
                        </div>
                      }
                    </mat-radio-group>
                  </div>
                }
                @if (q.SurveyQuestionType === 'State') {
                  <div class="flex flex-col flex-grow mx-10 mt-20">
                    <label [for]="q.AnswerValue" class="mb-10 text-sm label is-semibold is-white">{{q.SurveyQuestionText}} @if (q.IsRequired) {
                      <span
                      class="text-red">(required)</span>
                    }</label>
                    <mat-select class="h-40 m-0 select is-primary-2 md-no-underline"
                      (selectionChange)="questionAnswered(q.SurveyQuestionId, q.PossibleAnswers[0].PossibleAnswerId, $event.value)">
                      @for (state of states; track state) {
                        <mat-option class="w-full h-40" [value]="state.ShortName">
                          {{state.LongName}}
                        </mat-option>
                      }
                    </mat-select>
                  </div>
                }
                @if (q.SurveyQuestionType === 'SalesConsultant') {
                  <div class="flex flex-col flex-grow mx-10 mt-20">
                    <label [for]="q.AnswerValue" class="mb-10 text-sm label is-semibold is-white">{{q.SurveyQuestionText}} @if (q.IsRequired) {
                      <span
                      class="text-red">(required)</span>
                    }</label>
                    <mat-select class="h-40 m-0 select is-primary-2 md-no-underline"
                      (selectionChange)="questionAnswered(q.SurveyQuestionId, q.PossibleAnswers[0].PossibleAnswerId, $event.value)">
                      @for (sc of salesConsultants; track sc) {
                        <mat-option class="flex flex-row items-center w-full h-40"
                          [value]="sc.PersonId">
                          {{sc.FirstName}} {{sc.LastName}}
                        </mat-option>
                      }
                    </mat-select>
                  </div>
                }
                @if (q.SurveyQuestionType === 'Textarea') {
                  <div class="flex flex-col flex-grow mx-10 mt-20">
                    <label [for]="q.AnswerValue" class="text-sm label is-semibold is-white">{{q.Question}} @if (q.IsRequired) {
                      <span
                      class="text-red">(required)</span>
                    }</label>
                    <span class="mb-5 label is-grey-light">{{q.TextDescription}}</span>
                    <textarea class="textarea is-primary-2" name="question{{index}}" rows="8"
                    (change)="textInputAnswered(q.SurveyQuestionId, q.PossibleAnswers[0].PossibleAnswerId, $event)"></textarea>
                  </div>
                }
                @if (q.SurveyQuestionType === 'Checkbox') {
                  <div class="flex flex-col flex-grow mx-10 mt-20">
                    <label for="checkboxAnswers" class="mb-5 text-sm label is-semibold is-white">{{q.SurveyQuestionText}} @if (q.IsRequired) {
                      <span
                      class="text-red">(required)</span>
                    }</label>
                    <div layout="row" layout-wrap>
                      @for (a of q.PossibleAnswers; track a) {
                        <div class="w-1/2">
                          @if (!a.IsText) {
                            <mat-checkbox name="checkboxAnswers" class="mb-5 checkbox is-primary-2"
                              (change)=" checkboxAnswered(q.SurveyQuestionId, $event);"
                              value="{{a.PossibleAnswerId}}">
                              {{a.PossibleAnswerText}}
                            </mat-checkbox>
                          }
                          @if (a.IsText) {
                            <input class="mb-5 input is-primary-2"
                              placeholder="{{a.PossibleAnswerText}}"
                              (change)="textInputAnswered(q.SurveyQuestionId, a.PossibleAnswerId, $event)">
                          }
                        </div>
                      }
                    </div>
                  </div>
                }
                @if (q.SurveyQuestionType === 'RadioList') {
                  <div class="flex flex-col flex-grow mx-10 mt-20">
                    <label for="radioSelection" class="mb-5 text-sm label is-semibold is-white">{{q.SurveyQuestionText}} 
                      @if (q.IsRequired) {
                        <span
                        class="text-red">(required)</span>
                      }
                    </label>
                    <div class="flex flex-row items-center justify-start">
                      <mat-radio-group class="flex flex-row" name="radioSelection"
                        (change)="questionAnsweredNoText(q.SurveyQuestionId, $event.value)">
                        @for (a of q.PossibleAnswers; track a) {
                          <div class="flex flex-row items-center w-full md:w-1/3">
                            @if (!a.IsText) {
                              <mat-radio-button class="p-10 radio-button is-primary-2"
                              [value]="a.PossibleAnswerId">{{a.PossibleAnswerText}}</mat-radio-button>
                            }
                          </div>
                        }
                      </mat-radio-group>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
          @if (disclaimer) {
            <div class="pt-20 mx-20 mb-10 border-t border-white border-solid">
              <p class="text-base text text-gray">{{disclaimer}}</p>
            </div>
          }
        </div>
      }@else{
        <div class="relative flex-grow p-20 m-auto loader-wrap" [@fadeIn]>
          <div class="loader-sm is-blue"></div>
        </div>
      }
    </section>
    <section class="flex flex-none mt-auto border-t border-solid p-30 border-gray-lighter">
      <button class="ml-auto button is-primary w-256" type="submit" [disabled]="!canSubmit">
        {{isSubmitting ? 'Submitting...' : 'Submit'}}
      </button>
    </section>
  </form>
}

@if (!toggleNewForm) {
  <form class="flex flex-col flex-shrink-0 w-full h-full overflow-hidden" name="enrollmentForm" (ngSubmit)="submit()">
    @if (!loading) {
      <section id="enrollmentFormToolbar"
        class="flex-none border-b border-solid p-30 border-gray-lighter">
        <h2 class="text-lg font-bold heading">
          @if (survey.SurveyId === 1025) {
            <span>
              It looks like you do not have an {{survey.SurveyName}} account. <br />
              Submit the form below to enroll today!
            </span>
          }@else {
            <span>
              Enroll in {{survey.SurveyName}} today!
            </span>
          }
        </h2>
        <button class="absolute top-0 right-0 m-20 icon-button is-primary is-inverted" aria-label="Close"
          (click)="closeModal(false)" type="button">
          <i class="material-icons">close</i>
        </button>
      </section>
    }
    <section div class="flex flex-col flex-grow min-h-0 overflow-y-auto p-30">
      @if (!loading) {
        <div>
          <div class="flex flex-row flex-wrap items-end">
            @for (q of questions; track q; let index = $index) {
              <div class="flex w-full sm:w-1/2">
                @if (q.SurveyQuestionType === 'Text') {
                  <div class="flex flex-col flex-grow mx-10 mt-20">
                    <label for="question{{index}}" class="mb-10 text-sm font-semibold label text-gray">
                      {{q.SurveyQuestionText}} 
                      @if (q.IsRequired) {
                        <span class="text-red">(required)</span>
                      }
                    </label>
                    <input class="h-40 px-10 border border-solid input is-inverted border-gray-light" type="text"
                      name="question{{index}}"
                      (change)="textInputAnswered(q.SurveyQuestionId, q.PossibleAnswers[0] ? q.PossibleAnswers[0].PossibleAnswerId : 0, $event)" />
                  </div>
                }
                @if (q.SurveyQuestionType === 'Radio') {
                  <div class="flex flex-col flex-grow mx-10 mt-20">
                    <label for="radioGroup{{index}}" class="mb-5 text-sm label is-semibold is-white">{{q.SurveyQuestionText}} @if (q.IsRequired) {
                      <span
                      class="text-red">(required)</span>
                    }</label>
                    <mat-radio-group layout="column" name="radioGroup{{index}}"
                      (change)="questionAnsweredNoText(q.SurveyQuestionId, $event.value)">
                      @for (a of q.PossibleAnswers; track a) {
                        <div class="flex flex-row items-center h-40">
                          @if (!a.IsText) {
                            <mat-radio-button class="w-full p-10 radio-button is-primary-2 md:w-1/3"
                              [value]="a.PossibleAnswerId">{{a.PossibleAnswerText}}
                            </mat-radio-button>
                          }
                        </div>
                      }
                    </mat-radio-group>
                  </div>
                }
                @if (q.SurveyQuestionType === 'State') {
                  <div class="flex flex-col flex-grow mx-10 mt-20">
                    <label for="stateSelection" class="mb-10 text-sm label is-semibold is-white">{{q.SurveyQuestionText}} @if (q.IsRequired) {
                      <span
                      class="text-red">(required)</span>
                    }</label>
                    <mat-select class="h-40 m-0 select is-primary-2 md-no-underline" name="stateSelection"
                      (selectionChange)="questionAnswered(q.SurveyQuestionId, q.PossibleAnswers[0].PossibleAnswerId, $event.value)">
                      @for (state of states; track state) {
                        <mat-option class="w-full h-40" [value]="state.ShortName">
                          {{state.LongName}}
                        </mat-option>
                      }
                    </mat-select>
                  </div>
                }
                @if (q.SurveyQuestionType === 'SalesConsultant') {
                  <div class="flex flex-col flex-grow mx-10 mt-20">
                    <label for="salesConsultants" class="mb-10 text-sm label is-semibold is-white">{{q.SurveyQuestionText}} @if (q.IsRequired) {
                      <span
                      class="text-red">(required)</span>
                    }</label>
                    <mat-select class="h-40 m-0 select is-primary-2 md-no-underline" name="salesConsultants"
                      (selectionChange)="questionAnswered(q.SurveyQuestionId, q.PossibleAnswers[0].PossibleAnswerId, $event.value)">
                      @for (sc of salesConsultants; track sc) {
                        <mat-option class="flex flex-row items-center w-full h-40"
                          [value]="sc.PersonId">
                          {{sc.FirstName}} {{sc.LastName}}
                        </mat-option>
                      }
                    </mat-select>
                  </div>
                }
                @if (q.SurveyQuestionType === 'Textarea') {
                  <div class="flex flex-col flex-grow mx-10 mt-20">
                    <label for="question{{index}}" class="text-sm label is-semibold is-white">{{q.Question}} @if (q.IsRequired) {
                      <span
                      class="text-red">(required)</span>
                    }</label>
                    <span class="mb-5 label is-grey-light">{{q.TextDescription}}</span>
                    <textarea class="textarea is-primary-2" name="question{{index}}" rows="8"
                    (change)="textInputAnswered(q.SurveyQuestionId, q.PossibleAnswers[0] ? q.PossibleAnswers[0].PossibleAnswerId : 0, $event)"></textarea>
                  </div>
                }
                @if (q.SurveyQuestionType === 'Checkbox') {
                  <div class="flex flex-col flex-grow mx-10 mt-20">
                    <label for="checkbox{{index}}" class="mb-5 text-sm label is-semibold is-white">{{q.SurveyQuestionText}} @if (q.IsRequired) {
                      <span
                      class="text-red">(required)</span>
                    }</label>
                    <div layout="row" layout-wrap>
                      @for (a of q.PossibleAnswers; track a) {
                        <div class="w-1/2">
                          @if (!a.IsText) {
                            <mat-checkbox class="mb-5 checkbox is-primary-2" name="checkbox{{index}}"
                              (change)=" checkboxAnswered(q.SurveyQuestionId, $event);"
                              value="{{a.PossibleAnswerId}}">
                              {{a.PossibleAnswerText}}
                            </mat-checkbox>
                          }
                          @if (a.IsText) {
                            <input class="mb-5 input is-primary-2"
                              placeholder="{{a.PossibleAnswerText}}"
                              (change)="textInputAnswered(q.SurveyQuestionId, a.PossibleAnswerId, $event)">
                          }
                        </div>
                      }
                    </div>
                  </div>
                }
                @if (q.SurveyQuestionType === 'RadioList') {
                  <div class="flex flex-col flex-grow mx-10 mt-20">
                    <label for="radioGroup{{index}}" class="mb-5 text-sm label is-semibold is-white">{{q.SurveyQuestionText}} @if (q.IsRequired) {
                      <span
                      class="text-red">(required)</span>
                    }</label>
                    <div class="flex flex-row items-center justify-start">
                      <mat-radio-group class="flex flex-row" name="radioGroup{{index}}"
                        (change)="questionAnsweredNoText(q.SurveyQuestionId, $event.value)">
                        @for (a of q.PossibleAnswers; track a) {
                          <div class="flex flex-row items-center w-full md:w-1/3"
                            >
                            @if (!a.IsText) {
                              <mat-radio-button class="p-10 radio-button is-primary-2"
                              [value]="a.PossibleAnswerId">{{a.PossibleAnswerText}}</mat-radio-button>
                            }
                          </div>
                        }
                      </mat-radio-group>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
          @if (disclaimer) {
            <div class="pt-20 mx-20 mb-10 border-t border-white border-solid">
              <p class="text-base text text-gray">{{disclaimer}}</p>
            </div>
          }
        </div>
      }@else {
        <div class="relative flex-grow p-20 m-auto loader-wrap" [@fadeIn]>
          <div class="loader-sm is-blue"></div>
        </div>
      }
    </section>
    <section class="flex flex-none mt-auto border-t border-solid p-30 border-gray-lighter">
      <button class="ml-auto button is-primary w-256" type="submit" [disabled]="!canSubmit">
        {{isSubmitting ? 'Submitting...' : 'Submit'}}
      </button>
    </section>
  </form>
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OrderModel } from 'src/app/shared/models/order.models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SurveyMarketingStrategyModel } from 'src/app/shared/models/surveyMarketingStrategy.models';
import { MarketingDollarsModel, AgentMarketingDollarsResponse } from 'src/app/shared/models/marketingdollar.models';
import { EntryResponse } from 'src/app/shared/models/generic.models';

@Injectable({
  providedIn: 'root'
})
export class PurchaseApiService {

  constructor(private http:HttpClient) { }

  purchase(order: OrderModel):Observable<string>{
    return this.http.post('/api/Orders/SubmitOrder?newPortal=true', order, {responseType: 'text'});
  }

  purchaseRenewal(order: OrderModel):Observable<any>{
    return this.http.post('/api/Orders/SubmitOrder?newPortal=true', order);
  }
  testComplete():Observable<any>{
    return this.http.post('/api/Orders/Test', null);
  }

  getAllOrders():Observable<OrderModel[]>{
    return this.http.get<OrderModel[]>('/api/Orders/All');
  }

  getOrder(orderId: string):Observable<OrderModel>{
    return this.http.get<EntryResponse<OrderModel>>(`/api/Orders/${orderId}`)
      .pipe(map((o:EntryResponse<OrderModel>) => o.Entry));
  }

  getMarketingStrategyOfOrder(orderId: string):Observable<SurveyMarketingStrategyModel>{
    return this.http.get<EntryResponse<SurveyMarketingStrategyModel>>(`/api/Orders/MarketingStrategy/${orderId}`)
      .pipe(map((o:EntryResponse<SurveyMarketingStrategyModel>) => o.Entry));
  }

  getMarketingStrategyByName(name: string):Observable<SurveyMarketingStrategyModel>{
    return this.http.get<SurveyMarketingStrategyModel>(`/api/Orders/MarketingStrategy/Name/${name}`);
  }

  getUserOrders(userId: string):Observable<OrderModel[]>{
    return this.http.get<OrderModel[]>(`/api/Orders/User/${userId}`);
  }

  getMarketingDollars():Observable<AgentMarketingDollarsResponse>{
    return this.http.get<AgentMarketingDollarsResponse>('/api/MarketingDollars');
  }

  createMarketingDollars(model: MarketingDollarsModel):Observable<any>{
    return this.http.post('/api/MarketingDollars', model);
  }
}

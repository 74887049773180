export enum MarketingStrategy {
  AllPointsMedia = 'AllPointsMedia',
  Elevate = 'Elevate',
  AffluentClientStrategies = 'AffluentClientStrategies',
  CarProgram = 'CarProgram',
  CottonSystem = 'CottonSystem',
  Livewire = 'Livewire',
  OnAir = 'OnAir',
  SocialSecurityResource = 'SocialSecurityResource',
  BoomingIncomePhase = 'BoomingIncomePhase',
  CollegePlanningRelief = 'CollegePlanningRelief',
  SurgeBc = 'SurgeBc',
  Momentum = 'Momentum',
  TipOftheTriangle = 'TipOftheTriangle',
  SalesCoachJimDunn = 'SalesCoachJimDunn',
  LeadClub = 'LeadClub',
  Capstone = 'Capstone',
  Next = 'Next',
  ElevateForLife = 'ElevateForLife',
  IulComplete = 'IulComplete',
  SalesCoachWindowsofOpportunity = 'SalesCoachWindowsofOpportunity',
  MillenniuMBusinessRetirementPlanReview = 'MillenniuMBusinessRetirementPlanReview',
  RetireUp = 'RetireUp',
  RightBridge = 'RightBridge',
  ForceCrm = 'ForceCrm',
  CreativeServices = 'CreativeServices',
  FigTalentSolutions = 'FigTalentSolutions',
  LtcTraining = 'LtcTraining',
  CollegeFundingEvolution = 'CollegeFundingEvolution',
  IComply = 'IComply',
  HegnaRHNTierI = 'HegnaRHNTierI',
  HegnaRHNTierII = 'HegnaRHNTierII',
  HegnaRHNTierIII = 'HegnaRHNTierIII',
  PowerOfZeroPlus = 'PowerOfZeroPlus',
  RetireUpFree = 'RetireUpFree',
  MarkEdwardGafneyDyp12 = 'MarkEdwardGafneyDyp12',
  MarkEdwardGafneyMdc10 = 'MarkEdwardGafneyMdc10',
  LoneBeaconStandard = 'LoneBeaconStandard',
  LoneBeaconPremium = 'LoneBeaconPremium',
  ElevateEnhanced = 'ElevateEnhanced',
  Truelytics = 'Truelytic'
}

<div class="max-h-9/10 text-grey-dark min-w-9/10">
  <header class="flex items-start justify-center p-10 border-b border-solid border-gray-lighter">
    <h2 class="m-10 text-xl heading">Purchase Options</h2>
    <button class="m-10 ml-auto icon-button is-primary is-inverted" aria-label="Close dialog" (click)="closeModal()"><i
        class="fig-24px fig-close"></i></button>
  </header>
  <section class="flex flex-col p-10 mt-10 overflow-auto">
    <app-purchase-page [purchaseModel]="purchaseModel" (purchaseComplete)="payComponentCallback($event)" (purchaseCanceled)="closeModal()">
    </app-purchase-page>
  </section>  
</div>

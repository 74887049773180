import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { PurchaseApiService } from 'src/app/core/services/http/purchase-api.service';
import { SurveyPurchaseModel } from 'src/app/shared/models/surveyMarketingStrategy.models';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { OrderModel } from 'src/app/shared/models/order.models';

@Component({
  selector: 'app-purchase-page',
  templateUrl: './purchase-page.component.html',
})
export class PurchasePageComponent implements OnInit {
  marketingDollarsToUse = 0;
  marketingDollars!: number;
  paymentAmount!: number;
  @Output() purchaseComplete: EventEmitter<any> = new EventEmitter();
  @Output() purchaseCanceled: EventEmitter<any> = new EventEmitter();
  @Input() purchaseModel!: SurveyPurchaseModel;
  selectedPurchaseOption!: number;
  submittingOrder = false;
  error = false;
  numberOfSeats = 0;
  paymentForm!: UntypedFormGroup;

  constructor(private purchaseApiService: PurchaseApiService) { }

  initForm(): void {
    this.paymentForm = new UntypedFormGroup({
      'selectedPurchaseOption': new UntypedFormControl(null),
      'marketingDollarsToUse': new UntypedFormControl(null)
    });
  }

  ngOnInit(): void {
    this.initForm();
    if (this.purchaseModel.PurchaseOptions.length === 1) {
      this.selectedPurchaseOption = 0;
      this.marketingDollarsToUse = 0;
      this.getPaymentAmount();
    }
  }

  getPaymentAmount(): void {

    if (this.purchaseModel.PurchaseOptions[this.selectedPurchaseOption]) {
      const preMarketingDollarPrice = this.purchaseModel.PurchaseOptions[this.selectedPurchaseOption].Amount;

      if (this.marketingDollarsToUse < 0) {
        this.marketingDollarsToUse = 0;
      }

      if (this.marketingDollarsToUse > this.marketingDollars) {
        this.marketingDollarsToUse = this.marketingDollars;
      }

      if (this.marketingDollarsToUse > preMarketingDollarPrice) {
        this.marketingDollarsToUse = preMarketingDollarPrice;
      }

      this.paymentAmount = preMarketingDollarPrice - this.marketingDollarsToUse;
    }
  }

  purchase(): void {
    const model = this.purchaseModel.PurchaseOptions[this.selectedPurchaseOption];
    const marketingStrategy = this.purchaseModel.PurchaseOptions[this.selectedPurchaseOption].MarketingStrategy;
    if (!marketingStrategy) return;
    this.submittingOrder = true;
    const order: OrderModel = {
      Description: model.Description,
      PaymentAmount: this.paymentAmount,
      ArcSurveyRenewalId: this.purchaseModel.ArcSurveyRenewalId,
      MarketingDollarsUsed: this.marketingDollarsToUse,
      MarketingStrategy: marketingStrategy,
      Total: this.purchaseModel.PurchaseOptions[this.selectedPurchaseOption].Amount,
      Frequency: '0',
      AdditionalInfo: {
        NumberOfSeats: this.numberOfSeats.toString(),
      }
    };

    if (order.ArcSurveyRenewalId) {
      this.purchaseApiService.purchaseRenewal(order).subscribe(res => {
        this.submittingOrder = false;

        let returnObj = {};
        if (this.paymentAmount > 0) {
          window.open(res.payUrl, '_blank');
          returnObj = {
            orderId: res.orderId,
            surveyRenewalId: order.ArcSurveyRenewalId,
            action: 'Order Submitted',
            paymentAmount: this.paymentAmount
          };
        } else {
          returnObj = {
            orderId: res.orderId,
            surveyRenewalId: order.ArcSurveyRenewalId,
            action: 'Order Completed',
            paymentAmount: this.paymentAmount
          };
        }
        this.purchaseComplete.emit(returnObj);
      });
    } else {
      this.purchaseApiService.purchase(order).subscribe(res => {

        this.submittingOrder = false;
        if (this.paymentAmount > 0) {
          window.open(res, '_blank');
        }
        this.purchaseComplete.emit({ paymentAmount: this.paymentAmount });
      }, error => {
        console.log(error);
        this.submittingOrder = false;
        this.error = true;
        this.purchaseCanceled.emit();
      });
    }
  }

  goBack(): void {
    this.purchaseCanceled.emit();
  }
}
